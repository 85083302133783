/* src/admin_elements/calculations/Rates/RatesCalculations.module.css */

/* Container */
.accordionContainer {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
}

/* Items */
.accordionItem {
  border-bottom: 1px solid #e5e7eb;
  padding: 0.5rem 0;
}

.accordionItem:last-child {
  border-bottom: none;
}

/* Header */
.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 0;
  font-weight: 600;
  font-size: 1rem;
}

.toggleIcon {
  font-size: 1.2rem;
  margin-left: 1rem;
}

/* Content */
.accordionContent {
  margin-top: 0.5rem;
  padding-left: 1rem;
  animation: fadeIn 0.2s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Table styling */
.dataTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.dataTable th,
.dataTable td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}

.dataTable th {
  background-color: #f5f5f5;
}

/* Error styling */
.error {
  color: red;
  font-weight: bold;
  margin-top: 0.5rem;
}

/* Button styling */
button {
  margin-right: 0.5rem;
  padding: 0.3rem 0.6rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Just a small note style */
.sectionNote {
  font-style: italic;
  color: #555;
  margin: 0.5rem 0;
}
