/* src/admin_elements/calculations/Final/FinalCalculations.module.css */

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.buttonGroup {
  display: flex;
  flex-direction: column; /* One column layout */
  gap: 1rem;             /* Spacing between buttons */
}

.calcButton {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.calcButton:hover {
  background-color: #0056b3;
}
