/* src/admin_elements/sideNav/SideNav.module.css */

/* Collapsed by default */
.sideNav {
  background-color: #2563eb;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: width 0.3s ease;
  overflow: hidden;
}

.sideNav:hover {
  width: 200px;
}

/* Nav sections */
.navSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
}

.sectionHeader {
  display: none;
  font-weight: bold;
  font-size: 0.9rem;
  color: #ffffff;
  margin: 0.75rem 1rem 0.25rem 1rem;
}

.sideNav:hover .sectionHeader {
  display: block;
}

/* Nav icons */
.navIcon,
.activeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.2s ease, transform 0.2s ease;
  width: 100%;
}

.navIcon:hover,
.activeIcon:hover {
  background-color: #1d4ed8;
  transform: scale(1.05);
}

.activeIcon {
  background-color: #1d4ed8; 
  color: #ffd700; /* highlight color */
}

/* Nav text (hidden until hover) */
.navText {
  display: none;
  margin-left: 0.75rem;
  white-space: nowrap;
  font-size: 1rem;
}

.sideNav:hover .navText {
  display: inline-block;
}

/* Divider */
.divider {
  width: 80%;
  margin: 0.5rem auto;
  border: 0;
  height: 1px;
  background-color: #ffffff;
  opacity: 0.3;
}
