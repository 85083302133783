/* styles/TradepersonList.module.css */

.tradepersonList {
    padding: 1rem;
    background-color: #f9f9f9;
  }
  
  .tradepersonList h2 {
    margin-bottom: 1rem;
  }
  
  .tradepersonList ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .tradepersonList li {
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .tradepersonList li:hover {
    background-color: #eaeaea;
  }
  