/* src/pages/Dashboard.css */

.dashboard-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.filters-section {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.filter-item {
  flex: 1;
  min-width: 250px;
}

.charts-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
}

@media (min-width: 768px) {
  .charts-section {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .charts-section {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.chart-container {
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
