/* src/styles/LandingPage.module.css */

.landingContainer {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content horizontally */
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Space below the landing section */
  }
  
  .animation {
    width: 200px;
    margin-right: 40px;
  }
  
  .description {
    flex: 1;
  }
  
  .description h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .description p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 25px;
  }
  
  .instruction {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
  }
  
  .arrowIcon {
    margin-right: 8px;
    animation: bounce 2s infinite;
    color: #007bff; /* Arrow color */
  }
  
  /* Animation for the arrow */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0); 
    }
    40% {
      transform: translateY(-10px); 
    }
    60% {
      transform: translateY(-5px); 
    }
  }
  