/* src/admin_elements/quickNav/QuickNavigationPanel.module.css */

/* Wider container, centered horizontally */
.quickNavContainer {
  max-width: 2000px; /* Increased width for more horizontal space */
  width: 90%; /* Occupies 90% of the parent container's width */
  margin: 1rem auto; /* 1rem top and bottom margin, auto left and right */
  padding: 0.6rem 1.2rem; /* Reduced padding for a slimmer look */
  background-color: #e0f7fa;
  border: 1.5px solid #00796b; /* Slightly thinner border */
  border-radius: 6px; /* Reduced border-radius for a sleeker look */
}

/* Header Row */
.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00796b; /* Horizontal separator */
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
}

/* Input Row */
.inputRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00796b; /* Horizontal separator */
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
}

/* Section Headers */
.sectionHeader {
  margin: 0;
  font-size: 1.2rem; /* Increased from 1rem to 1.2rem */
  color: #004d40;
  font-weight: 500; /* Medium font weight for better readability */
}

/* Header Section */
.headerSection {
  flex: 1;
  text-align: center;
  position: relative;
}

/* Input Section */
.inputSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Flex Container for Year and Quarter */
.yearQuarterContainer {
  display: flex;
  align-items: center;
  gap: 0.8rem; /* Space between elements */
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

/* Vertical Separator */
.headerSection:not(:last-child)::after,
.inputSection:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #00796b;
}

/* Labels */
.label {
  font-weight: 500; /* Medium font weight */
  font-size: 0.96rem; /* Increased from 0.8rem to 0.96rem (20% increase) */
  margin-bottom: 0.1rem;
}

/* Select Dropdowns */
.select {
  padding: 0.2rem 0.4rem; /* Further reduced padding */
  border: 1px solid #00796b;
  border-radius: 4px;
  font-size: 0.96rem; /* Increased from 0.8rem to 0.96rem (20% increase) */
  width: 120px; /* Increased width for better spacing */
}

/* Quarter Buttons */
.quarterSelector {
  display: flex;
  gap: 0.3rem; /* Reduced gap */
}

.quarterButton {
  background-color: #b2dfdb;
  color: #004d40;
  border: 1px solid #004d40;
  border-radius: 4px;
  padding: 0.24rem 0.48rem; /* 20% increase */
  font-size: 0.9rem; /* 20% increase */
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.quarterButton:hover {
  background-color: #80cbc4;
}

.activeQuarter {
  background-color: #00796b !important;
  color: #fff !important;
}

/* Data Agreement Navigation */
.dataAgreementNav {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Reduced gap */
  margin-top: 0.1rem;
}

/* Navigation Buttons */
.navButton {
  padding: 0.24rem 0.48rem; /* 20% increase */
  background-color: #00796b;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem; /* 20% increase */
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover:not(:disabled) {
  background-color: #005b5b;
}

.navButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Upload Button */
.uploadButton {
  display: flex;
  align-items: center;
  gap: 0.48rem; /* 20% increase */
  padding: 0.36rem 0.72rem; /* 20% increase */
  background-color: #10b981;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem; /* 20% increase */
  transition: background-color 0.2s ease;
}

.uploadButton:hover {
  background-color: #059669;
}

/* Tradeperson Tiles */
.tradepersonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem; /* Further reduced gap */
  justify-content: center;
  margin-top: 0.8rem; /* Space from the QuickNav */
}

.tradepersonTile {
  background-color: #b2dfdb;
  padding: 0.36rem 0.72rem; /* 20% increase */
  border: 1px solid #004d40;
  border-radius: 4px;
  font-size: 0.9rem; /* 20% increase */
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  text-align: center;
}

.tradepersonTile:hover {
  background-color: #80cbc4;
}

/* Selected Tradeperson Tile */
.selected {
  background-color: #00796b;
  color: #fff;
  border-color: #005b5b;
}

.noTradepersons {
  margin-top: 0.6rem; /* Further reduced top margin */
  color: #004d40;
  text-align: center;
  font-size: 0.96rem; /* 20% increase */
}

/* Error Message Styling */
.error {
  color: #dc2626;
  text-align: center;
  font-size: 0.96rem; /* 20% increase */
}

/* Responsive Design */
@media (max-width: 2000px) {
  .quickNavContainer {
    max-width: 1800px; /* Further increased for very large screens */
  }
}

@media (max-width: 1600px) {
  .quickNavContainer {
    max-width: 1400px;
  }
}

@media (max-width: 1200px) {
  .quickNavContainer {
    max-width: 1200px;
  }

  .select {
    width: 100px;
    font-size: 0.96rem;
  }
}

@media (max-width: 992px) {
  .headerRow,
  .inputRow {
    gap: 0.5rem;
  }

  .select {
    width: 90px;
    font-size: 0.96rem;
  }

  .quarterSelector {
    gap: 0.24rem;
  }

  .dataAgreementNav {
    gap: 0.48rem;
  }

  .uploadButton {
    padding: 0.28rem 0.86rem;
    font-size: 0.84rem;
  }

  .quarterButton {
    padding: 0.24rem 0.6rem;
    font-size: 0.84rem;
  }

  .navButton {
    padding: 0.24rem 0.6rem;
    font-size: 0.84rem;
  }

  .tradepersonTile {
    min-width: 90px;
    font-size: 0.84rem;
    padding: 0.36rem 0.72rem;
  }
}

@media (max-width: 768px) {
  .quickNavContainer {
    padding: 0.6rem 1rem;
  }

  .headerRow,
  .inputRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .headerSection,
  .inputSection {
    width: 100%;
    align-items: flex-start;
  }

  .yearQuarterContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
  }

  .quarterSelector {
    gap: 0.24rem;
  }

  .tradepersonContainer {
    gap: 0.4rem;
  }

  .tradepersonTile {
    padding: 0.36rem 0.72rem;
    font-size: 0.9rem;
    min-width: 80px;
  }

  .noTradepersons,
  .error {
    font-size: 0.9rem;
  }
}
