/* src/admin_elements/calculations/Rates/RatesCalculations.module.css */

/* Container */
.accordionContainer {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
}

/* Items */
.accordionItem {
  border-bottom: 1px solid #e5e7eb;
  padding: 0.5rem 0;
}
.accordionItem:last-child {
  border-bottom: none;
}

/* Header */
.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 0;
  font-weight: 600;
  font-size: 1rem;
}
.toggleIcon {
  font-size: 1.2rem;
  margin-left: 1rem;
}

/* Content */
.accordionContent {
  margin-top: 0.5rem;
  padding-left: 1rem;
  animation: fadeIn 0.2s ease-in;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Data table styles */
.dataTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}
.dataTable th, .dataTable td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}
.dataTable th {
  background-color: #f5f5f5;
}

.error {
  color: red;
  font-weight: bold;
  margin-top: 0.5rem;
}

.editButton {
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.editButton:hover {
  background-color: #45a049;
}

input[type='text'] {
  width: 100px;
  padding: 0.2rem;
  margin-right: 0.5rem;
}

button {
  margin-left: 0.2rem;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}