/* src/styles/Admin.module.css */

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f3f4f6;
}

.mainContent {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/* SideNav panel */
.leftPanel {
  flex-shrink: 0;
  height: 100%;
}

/* Right content area */
.contentArea {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background-color: #fff;
}

/* Quick nav at top (if you have it) */
.quickNav {
  flex: 0 0 auto;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Middle panel area */
.middlePanel {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #fefefe;
}

/* Loading and error text */
.loading {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}

.error {
  text-align: center;
  color: red;
  font-weight: bold;
}

/* Container that holds the 50/50 split */
.calcContainer {
  display: flex;
  gap: 1rem; /* Some spacing between sections */
}

/* Left side: 50% width */
.ratesSection {
  flex: 0 0 50%;
  background: #fafafa; /* optional background color */
  padding: 1rem;
  border: 1px solid #ccc; /* optional border */
  border-radius: 5px;     /* optional border radius */
}

/* Right side: 50% width */
.graphSection {
  flex: 0 0 50%; /* so it’s 50% wide side-by-side with calc section */
  min-height: 400px; /* ensures there's vertical space for chart */
  position: relative; /* recommended if using ResponsiveContainer */
  background: #ffffff;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;

}


/* Table Styles (if any) */
.dataTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.dataTable th,
.dataTable td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}

.dataTable th {
  background-color: #f2f2f2;
}

/* Button Styles */
.tableButtonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tableButton {
  padding: 0.5rem 1rem;
  background-color: #0070f3;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tableButton:hover {
  background-color: #005bb5;
}

.expanded {
  background-color: #005bb5;
}

/* Navigation Link Styles */
.navLink {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.navLink:hover {
  background-color: #218838;
}

/* Responsive Design */
@media (max-width: 768px) {
  .calcContainer {
    flex-direction: column;
  }

  .ratesSection,
  .graphSection {
    flex: 0 0 100%;
  }

  .selectInput {
    max-width: 100%;
  }

  .dataTable th,
  .dataTable td {
    padding: 0.3rem;
  }

  .tableButton {
    padding: 0.3rem 0.6rem;
  }

  .navLink {
    padding: 0.3rem 0.6rem;
  }
}
