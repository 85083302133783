/* styles/FactDataAgreementPanel.module.css */

.panel {
  padding: 1rem;
  background-color: #f9f9f9;
}

.recordDetails {
  margin-bottom: 1rem;
}

.recordRow {
  margin-bottom: 0.5rem;
}

.recordRow strong {
  display: inline-block;
  width: 150px;
}
