/* src/styles/RatesGraph.module.css */

.container {
    padding: 20px;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .filtersSection {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .filterItem {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #555;
  }
  
  .error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .chartsSection {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .chartContainer {
    flex: 1 1 100%; /* Full width for single chart */
    min-width: 300px;
  }
  
  .noData {
    font-size: 16px;
    color: #777;
  }
  