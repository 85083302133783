/* src/admin_elements/calculations/Superable/SuperableCalculations.module.css */

/* Container */
.accordionContainer {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
}

/* Accordion Items */
.accordionItem {
  border-bottom: 1px solid #e5e7eb;
  padding: 0.5rem 0;
}

.accordionItem:last-child {
  border-bottom: none;
}

/* Accordion Header */
.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 0;
  font-weight: 600;
  font-size: 1rem;
}

.toggleIcon {
  font-size: 1.2rem;
  margin-left: 1rem;
}

/* Accordion Content */
.accordionContent {
  margin-top: 0.5rem;
  padding-left: 1rem;
  animation: fadeIn 0.2s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Table Styling */
.dataTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.dataTable th,
.dataTable td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}

.dataTable th {
  background-color: #f5f5f5;
}

/* Error Styling */
.error {
  color: red;
  font-weight: bold;
  margin-top: 0.5rem;
}

/* Generic Button Styling */
.button {
  padding: 0.4rem 0.8rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Table Buttons Container */
.tableButtonContainer {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next line if necessary */
  gap: 0.5rem; /* Space between buttons */
  margin-bottom: 1rem; /* Space below the buttons */
}

/* Individual Table Button */
.tableButton {
  padding: 0.5rem 1rem;
  background-color: #17a2b8; /* Teal color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tableButton:hover {
  background-color: #138496; /* Darker teal on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Action Buttons Container */
.actionButtons {
  display: flex;
  gap: 0.5rem; /* Space between Delete and Edit buttons */
}

/* Specific Styles for Delete Button */
.deleteButton {
  background-color: #dc3545; /* Red color */
}

.deleteButton:hover {
  background-color: #c82333; /* Darker red on hover */
}

/* Specific Styles for Edit Button */
.editButton {
  background-color: #28a745; /* Green color */
}

.editButton:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .tableButtonContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .actionButtons {
    flex-direction: column;
    align-items: stretch;
  }

  .tableButton,
  .deleteButton,
  .editButton {
    width: 100%; /* Make buttons full width on small screens */
  }
}
