/* src/components/EditableTable.module.css */

.editableTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.editableTable th,
.editableTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.editableTable th {
  background-color: #f2f2f2;
  text-align: left;
}

.editButton,
.saveButton,
.cancelButton {
  margin-left: 8px;
  padding: 4px 8px;
  background-color: transparent; /* Make background transparent for icon buttons */
  color: #008cba; /* Blue color for icons */
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.editButton:hover,
.saveButton:hover,
.cancelButton:hover {
  background-color: rgba(0, 140, 186, 0.1); /* Light blue hover effect */
}

.saveButton {
  color: #4caf50; /* Green color for Save */
}

.cancelButton {
  color: #f44336; /* Red color for Cancel */
}

.editingCell {
  display: flex;
  align-items: center;
}

.inputField {
  width: 70%;
  padding: 4px;
  margin-right: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cellContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error {
  color: red;
  margin-bottom: 0.5rem;
}
