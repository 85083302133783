/* src/styles/ExcelUploadModal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.modalOverlayAfterOpen {
  opacity: 1;
}

.modalOverlayBeforeClose {
  opacity: 0;
}

.modalContent {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  transform: scale(0.9);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

.modalContentAfterOpen {
  transform: scale(1);
  opacity: 1;
}

.modalContentBeforeClose {
  transform: scale(0.9);
  opacity: 0;
}

.instructions {
  margin-bottom: 20px;
  font-size: 0.95em;
  color: #555;
}

.instructions h3 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #333;
}

.instructions ul {
  list-style-type: disc;
  padding-left: 20px;
}

.instructions ul ul {
  list-style-type: circle;
  padding-left: 20px;
}

.instructions a {
  color: #007bff;
  text-decoration: none;
}

.instructions a:hover {
  text-decoration: underline;
}

.fileInput {
  margin-bottom: 10px;
}

.error {
  background-color: #ffe6e6;
  border: 1px solid #ffcccc;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  color: #cc0000;
}

.progressBar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
  height: 20px;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  width: 0%;
  transition: width 0.3s ease;
}

.progressBar span {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9em;
  color: #fff;
}

.parsedDataContainer {
  margin-top: 20px;
}

.parsedDataContainer h3 {
  margin-bottom: 10px;
  color: #333;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.uploadButton,
.cancelButton,
.validateButton {
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.uploadButton {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.uploadButton:disabled {
  background-color: #94d3a2;
  cursor: not-allowed;
}

.uploadButton:hover:not(:disabled) {
  background-color: #218838;
}

.cancelButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.cancelButton:disabled {
  background-color: #e79fa3;
  cursor: not-allowed;
}

.cancelButton:hover:not(:disabled) {
  background-color: #c82333;
}

.validateButton {
  background-color: #17a2b8;
  color: #fff;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.validateButton:disabled {
  background-color: #8fd4e6;
  cursor: not-allowed;
}

.validateButton:hover:not(:disabled) {
  background-color: #138496;
}
