/* src/styles/ParsedDataDisplay.module.css */

.sheetContainer {
    margin-bottom: 20px;
  }
  
  .sheetContainer h4 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
  }
  
  .tableWrapper {
    overflow-x: auto; /* Enables horizontal scrolling if table is too wide */
    max-height: 400px; /* Adjust as needed */
    overflow-y: auto; /* Enables vertical scrolling if table is too tall */
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .dataTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dataTable th,
  .dataTable td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .dataTable th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .dataTable tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .dataTable tr:hover {
    background-color: #f1f1f1;
  }
  