/* src/styles/OnCostsCalculations.module.css */

.accordionContainer {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
}

.accordionItem {
  margin-bottom: 1rem;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f7f7f7;
  padding: 0.5rem;
  border-radius: 3px;
}

.toggleIcon {
  font-weight: bold;
}

.accordionContent {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.dataTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.dataTable th,
.dataTable td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}

.dataTable th {
  background-color: #f2f2f2;
}

.error {
  color: red;
}

.success {
  color: green;
}

.tableButtonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tableButton {
  padding: 0.5rem 1rem;
  background-color: #0070f3;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.tableButton:hover {
  background-color: #005bb5;
}

.loading {
  color: #0070f3;
}
